import React, { useEffect, useState } from 'react';
import chair from '../img/chair.png';
import insurances from '../data/insurances'

function Coverage(props) {
  let [coverage, setCoverage] = useState([])

  useEffect(() => {
    setCoverage(insurances)
  }, [])

    return (
      <div className='component-page' >
        <div className='chair-component'>
          <img src={chair} className='chair-img' />
        </div>

        <div className='component-body-right' id='coverage'>
          <div className='component-title'>Coverage & Fees</div>
            <div className='coverage-fees'>
              {coverage.map(pg => (<div key={pg.id} className='paragraph'>
                  <div><strong>{pg.bold}</strong>{pg.paragraph}</div>
              </div>))}
            </div>
        </div>
      </div>
    )
}

export default Coverage
import React from 'react';
import { Landing, Nav, About, Experience, Interests, Services, Coverage, Publications, Contact, Footer } from './components';
import { Route, Routes } from 'react-router-dom';

function AppRoutes() {
  return (
    <div className="App-Routes">
      <Routes>
        <Route path='/' element={<>
          <Landing />
          <Nav />
          <About />
          <Experience />
          <Interests />
          <Services />
          <Coverage />
          {/* <Publications /> */}
          <Contact />
          <Footer />
        </>} />
      </Routes>
    </div>
  );
}

export default AppRoutes;
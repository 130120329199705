const insurances = [
    {
        id: 1,
        bold: "In-Network Coverage: ",
        paragraph: "I accept Blue Cross and Blue Shield PPO plans, as well as Blue Choice and Unitedhealthcare."
    },
    {
        id: 2,
        bold: "Out-of-Network Coverage: ",
        paragraph: "For all other insurances, I accept your insurance on an out-of-network basis and services will apply towards your plan's out-of-network benefits. Generally a percentage of my full fee is reimbursed by insurance plans that have out-of-network coverage. I will provide you with a statement, called a superbill, which you can submit to your insurance for reimbursement of out-of-network charges."
    },
    {
        id: 3,
        bold: "Standard Fee: ",
        paragraph: "My out of pocket rate for the initial two consultations sessions are $200 and ongoing psychotherapy sessions are $175. Sessions are roughly 55 minutes in length."
    },
    {
        id: 4,
        bold: "",
        paragraph: "I offer a sliding scale. Reach out directly to learn more about my sliding scale fee."
    }
]

export default insurances
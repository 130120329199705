const abouts = [
    {
        id: 1,
        paragraph: "I believe that all people have dignity, worth, and the right to live life in a way that brings them joy. Our ability to live joyfully can be limited due to imbalances of power in our world (racism, sexism, homophobia). Because of this we often suffer, and suffering left unaddressed can lead to limiting beliefs and behaviors. These behaviors are usually adopted as a matter of necessity to survive, yet they can keep us from being the most authentic and happy version of ourselves. The therapeutic relationship can heal and transform our lives through affirmation, validation and sustained attention on our thoughts, beliefs and behaviors."
    },
    {
        id: 2,
        paragraph: "My approach is relational-psychodynamic, trauma-informed, uses mindfulness and is anti-oppressive. This means we will create space for us to explore and affirm your experiences. You will share your vision of what your happiness looks like, and we will partner to make that vision a reality by addressing barriers and practicing tools to realize your vision. My practice is rooted in modalities with extensive research supporting their efficacy."
    },
    {
        id: 3,
        paragraph: "I have been a helper to many of all ages. I have worked with children using play therapy, students of every age, immigrant families and military families. I have helped individuals and couples. I strive to be an ally to people of all body sizes and abilities, and affirm queer people and relationships. I welcome the opportunity to work with you."
    }
]

export default abouts
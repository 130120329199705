import React, { useEffect, useState } from 'react';
import headshot from '../img/ck-bw.jpg';
import abouts from '../data/abouts'

function About(props) {
  let [about, setAbout] = useState([])
  let [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    setAbout(abouts)
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [])

    return (
      <div className='component-page' id='about'>
        {(window.innerWidth < 770) ? 
        (<>

          <div className='component-body-right'>
            <div className='about-title'>Cary L Klemmer, PhD</div>
            <div className='about-sub-title'>Licensed Clinical Social Worker</div>
            <div className='about'>
                <div>
                  {about.map(pg => (<div key={pg.id} className="paragraph">
                    {pg.paragraph}
                  </div>))}
                </div>
            </div>
          </div>
          <div className='about-body-left'>
              <img src={headshot} className='bio-image' />
          </div>
        </>) : 
        (<>
          <div className='about-body-left'>
              <img src={headshot} className='bio-image' />
          </div>
          <div className='component-body-right'>
            <div className='about-title'>Cary L Klemmer, PhD</div>
            <div className='about-sub-title'>Licensed Clinical Social Worker</div>
            <div className='about'>
                <div>
                  {about.map(pg => (<div key={pg.id} className="paragraph">
                    {pg.paragraph}
                  </div>))}
                </div>
            </div>
          </div>

        </>)}

      </div>
    )
}

export default About
import React, { useEffect, useState } from 'react';
import publicationsDb from '../data/publications'

function Publications(props) {
  let [publications, setPublications] = useState([])

  useEffect(() => {
    setPublications(publicationsDb)
  }, [])

    return (
      <div className='component-page' id='publications'>
        <div className='component-body-whole'>
          <div className='component-title'>Selected Publications</div>
          <div>
                <div className='pub-grid'>
                {publications.map(pg => (<div key={pg.id}>
                  <a href={pg.url} target="_blank"><div className='pub-grid-item'>{pg.title}, <i>{pg.publisher}</i>, {pg.year}</div></a>
                </div>))}
                <a href='https://scholar.google.com/citations?user=VEzbA5MAAAAJ&hl=en' target="_blank"><div className='pub-grid-item'>Full list of scholarship here.</div></a>
              </div>
          </div>
        </div>
      </div>
    )
}

export default Publications
import React, { useEffect, useState } from 'react';
import experiences from '../data/experiences'

function Experience(props) {
  let [experience, setExperience] = useState([])

  useEffect(() => {
    setExperience(experiences)
  }, [])

    return (
      <div className='component-page'>
        <div className='component-body-whole' id='experience'>
            <div className='experience'>
              <div className='experience-title'>Training & Experience</div>
                <div>{experience.map(pg => (<div key={pg.id} className='paragraph'>
                  <div className='experience-paragraphs'>{pg.paragraph}</div>
                </div>))}</div>
            </div>
          </div>
      </div>
    )
}

export default Experience
const experiences = [
    {
    id: 1,
    paragraph: "I completed a Masters in Social Work (2016) and a PhD in Social Work (2020) at the University of Southern California in Los Angeles, CA. Presently, I am an ongoing Fellow at the Chicago Center for Psychoanalysis and Psychotherapy (CCP) in Chicago, IL.",
    createdAt: "2023-05-08T20:59:55.699Z",
    updatedAt: "2023-05-08T20:59:55.699Z"
    },
    {
    id: 2,
    paragraph: "I began my work as a helping professional over a decade ago in a community mental health services setting in San Diego, CA. Since, I have conducted extensive research on the needs of LGBTQIA+ people across the lifespan and have worked in a variety of settings including medical (e.g., oncology and HIV services), the military, acute in-patient psychiatric and out-patient mental health. I have worked in settings that prioritize services to people of diverse cultural, ethnic, linguistic and racial backgrounds.",
    createdAt: "2023-05-08T20:59:55.699Z",
    updatedAt: "2023-05-08T20:59:55.699Z"
    },
    {
    id: 3,
    paragraph: "I have received training in cognitive therapies (i.e., CBT, DBT and ACT), mindfulness and trauma-informed modalities, substance use counseling and harm reduction. My primary speciality is as a relational psychoanalytic psychotherapist. This modality employs an open-ended stance of curiosity about one's experience and encourages free association, emotional engagement, and inquiry to increase self-understanding and capacity to hold all of ones self.",
    createdAt: "2023-05-08T20:59:55.699Z",
    updatedAt: "2023-05-08T20:59:55.699Z"
    }
]

export default experiences
import React, { useEffect, useState } from 'react';
import servicesDb from '../data/services'

function Services(props) {
  let [services, setServices] = useState([])

  useEffect(() => {
    setServices(servicesDb)
  }, [])

    return (
      <div className='component-page' >
        <div className='component-body-right' id='services'>
            <div className='services-title'>Services & Location</div>
              {services.map(pg => (<div key={pg.id} className='services'>
                  <div className='paragraph'>{pg.paragraph1}<strong>{pg.bold1}</strong>{pg.paragraph2}</div>
              </div>))}
              <div className='services'>
              <div className='paragraph'>I see clients in-person in the Andersonville neighborhood of Chicago and via Tele-health.</div>
              <div className='paragraph'>
                <strong>Address:</strong><br />
                5547 N Ravenswood Ave, Suite 407<br />
                Chicago*, IL 60640
              </div>
              </div>
              
        </div>
        <div className='component-body-left'>
            
        </div>
      </div>
    )
}

export default Services


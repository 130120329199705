const interests = [
    {
    id: 1,
    item: "Psychodynamic & Psychoanalytic Therapy",
    createdAt: "2023-05-08T20:59:55.729Z",
    updatedAt: "2023-05-08T20:59:55.729Z"
    },
    {
    id: 3,
    item: "Grief, Loss & Mourning",
    createdAt: "2023-05-08T20:59:55.729Z",
    updatedAt: "2023-05-08T20:59:55.729Z"
    },
    {
        id: 13,
        item: "Spirituality & Meaning Making",
        createdAt: "2023-05-08T20:59:55.729Z",
        updatedAt: "2023-05-08T20:59:55.729Z"
        },
    {
    id: 6,
    item: "Sex & Relationships",
    createdAt: "2023-05-08T20:59:55.729Z",
    updatedAt: "2023-05-08T20:59:55.729Z"
    },
    {
    id: 7,
    item: "Life transitions",
    createdAt: "2023-05-08T20:59:55.729Z",
    updatedAt: "2023-05-08T20:59:55.729Z"
    },
    {
    id: 8,
    item: "Immigration & Children of Immigrants",
    createdAt: "2023-05-08T20:59:55.730Z",
    updatedAt: "2023-05-08T20:59:55.730Z"
    },
    {
    id: 11,
    item: "Family Conflict & Estrangement",
    createdAt: "2023-05-08T20:59:55.730Z",
    updatedAt: "2023-05-08T20:59:55.730Z"
    },
    {
    id: 4,
    item: "Anxiety & Depression",
    createdAt: "2023-05-08T20:59:55.729Z",
    updatedAt: "2023-05-08T20:59:55.729Z"
    },
    {
        id: 14,
        item: "Attention & Focus",
        createdAt: "2023-05-08T20:59:55.729Z",
        updatedAt: "2023-05-08T20:59:55.729Z"
        },
    {
    id: 5,
    item: "ADHD",
    createdAt: "2023-05-08T20:59:55.729Z",
    updatedAt: "2023-05-08T20:59:55.729Z"
    },
    {
    id: 9,
    item: "Biculturality & Multiculturality",
    createdAt: "2023-05-08T20:59:55.730Z",
    updatedAt: "2023-05-08T20:59:55.730Z"
    },
    {
    id: 10,
    item: "Sexuality & Gender Identity",
    createdAt: "2023-05-08T20:59:55.730Z",
    updatedAt: "2023-05-08T20:59:55.730Z"
    },
    {
        id: 12,
        item: "Masculinity & Gender",
        createdAt: "2023-05-08T20:59:55.730Z",
        updatedAt: "2023-05-08T20:59:55.730Z"
        },
    {
    id: 2,
    item: "Trauma & Traumatic Stress",
    createdAt: "2023-05-08T20:59:55.729Z",
    updatedAt: "2023-05-08T20:59:55.729Z"
    }
]

export default interests
const publications = [
    {
        id: 1,
        url: "https://journals.sagepub.com/doi/abs/10.1177/0886109919894650?journalCode=affa",
        title: "Deep Ecology and Ecofeminism: Social Work to Address Global Environmental Crisis",
        publisher: "Affilia",
        year: "2019"
    },
    {
        id: 2,
        url: "https://www.tandfonline.com/doi/abs/10.1080/19317611.2019.1667938",
        title: "Missing from the Conversation: Sexual Risk Factors Across Young People by Gender Identity and Sexual Orientation",
        publisher: "International Journal of Sexual Health",
        year: "2019"
    },
    {
        id: 3,
        url: "https://journals.sagepub.com/doi/abs/10.1177/0044118X19854783?journalCode=yasa",
        title: "Differential Sexual Behavior Experiences of LGBQ and Transgender/ Nonbinary Young People in Colorado",
        publisher: "Youth & Society",
        year: "2019"
    },
    {
        id: 8,
        url: "https://jamanetwork.com/journals/jamanetworkopen/article-abstract/2749444",
        title: "Transgender and gender-diverse adolescents' experiences of violence, depression, anxiety, and suicidal ideation in the People's republic of China",
        publisher: "JAMA",
        year: "2019"
    },
    {
        id: 9,
        url: "https://www.sciencedirect.com/science/article/abs/pii/S1054139X20303190",
        title: "Depression and suicide risk at the cross-section of sexual orientation and gender identity for youth",
        publisher: "Elsevier",
        year: "2021"
    },
    {
        id: 10,
        url: "https://www.taylorfrancis.com/chapters/edit/10.4324/9780429443176-10/substance-use-transgender-nonbinary-populations-gio-dolcecore-isaac-akapnitis-trey-jenkins-cary-leonard-klemmer",
        title: "Substance use and transgender nonbinary populations: Towards inclusive prevention and service provision",
        publisher: "Routledge",
        year: "2020"
    },
    {
        id: 11,
        url: "https://link.springer.com/chapter/10.1007/978-3-030-52612-2_5",
        title: "Violence Against Sexual and Gender Minority Youth: Social Interventions of the Twenty-First Century",
        publisher: "Springer International Publishing",
        year: "2021"
    }
]

export default publications
const services = [
    {
    id: 1,
    paragraph1: "I provide ",
    bold1: "individual, family and couples psychotherapy",
    paragraph2: " to people across the lifespan. We will meet 1-2 times for initial consultation and then decide the format (e.g., modality and meeting frequency) that is most suited to your goals and objectives.",
    createdAt: "2023-05-08T20:59:55.721Z",
    updatedAt: "2023-05-08T20:59:55.721Z"
    },
    {
    id: 2,
    paragraph1: "I provide psychodynamic ",
    bold1: "clinical supervision and consultation",
    paragraph2: " for experienced clinicians as well as those in training who seek independent licensure.",
    createdAt: "2023-05-08T20:59:55.721Z",
    updatedAt: "2023-05-08T20:59:55.721Z"
    }
]

export default services